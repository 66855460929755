exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clani-js": () => import("./../../../src/pages/clani.js" /* webpackChunkName: "component---src-pages-clani-js" */),
  "component---src-pages-dirigent-js": () => import("./../../../src/pages/dirigent.js" /* webpackChunkName: "component---src-pages-dirigent-js" */),
  "component---src-pages-galerija-galerija-js": () => import("./../../../src/pages/galerija/[...galerija].js" /* webpackChunkName: "component---src-pages-galerija-galerija-js" */),
  "component---src-pages-galerija-js": () => import("./../../../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-koncerti-js": () => import("./../../../src/pages/koncerti.js" /* webpackChunkName: "component---src-pages-koncerti-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-orkester-js": () => import("./../../../src/pages/orkester.js" /* webpackChunkName: "component---src-pages-orkester-js" */),
  "component---src-pages-podporniki-js": () => import("./../../../src/pages/podporniki.js" /* webpackChunkName: "component---src-pages-podporniki-js" */)
}

